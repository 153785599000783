import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { VueAxios } from './utils/request'
import VuewechatTitle from 'vue-wechat-title' 
import Vant from 'vant'
import {i18n, vantLocales} from './lang'
vantLocales(i18n.locale)
const app = createApp(App)
 


// 2. 引入组件样式
import 'vant/lib/index.css'
app.use(router).use(Vant).use(VueAxios).use(i18n).use(VuewechatTitle).mount('#app')
 