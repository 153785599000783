
// en.js
export default {
  corporateName:'information collection',
  custom: {
    title: 'feedback',
    copywriting: 'Please complete the following for registration.',
    forms: {
      fieldcustomerName: {
        label: 'Name：',
        placeholder: 'Please input your name：',
      },
      fieldgender: {
        label: 'Gender：',
        placeholder: 'Please select your gender：',
        dropdown: {
          option1: 'male',
          option2: 'female'
        }
      },
      fieldcustomerAge: {
        label: 'Age：',
        placeholder: 'Please enter Age：',
      },
      fieldacquisitionDate: {
        label: 'Collection date：',
        placeholder: 'Click to choose collection date：',
      },
      fieldContactInformation: {
        label: 'Contact information：',
        placeholder: 'Please enter your contact information (phone/email)：',
      },
      fieldSampleSendingUnit: {
        label: 'Sample submit unit：',
        placeholder: 'Please enter the Sample submit unit：',
      },
      fieldarea: {
        label: 'Area:',
        placeholder: 'Please select your area:',
        cascaderTitle: 'Please select your area:'
      },
      fieldSpecificUnit: {
        label: 'Adress in detail：',
        placeholder: 'Please provide your adress in detail：'

      },
      fieldSymptom: {
        label: 'Detailed Symptom Description：',
        placeholder: 'Please provide your detailed Symptom Description：'

      },
      fieldRemarks:{
        label: 'Remarks',
        placeholder: 'Please provide your exact Remarks'

      },
      submitButton: 'Submitted'




    }

  },
  pet: {
    title: 'feedback',
    copywriting: 'Please complete the following for registration.',
    forms: {
      fieldParentName: {
        label: 'Pets owner：',
        placeholder: 'Please enter the parents name：',
      },
      fieldPetName: {
        label: 'Pets name:：',
        placeholder: 'Please enter the Pets owner：',
      },
      fieldDogBreed: {
        label: 'Dog breed/cat breed：',
        placeholder: 'Please enter the Dog breed/cat breed：',
      },
      fieldGender: {
        label: 'Pets gender',
        placeholder: 'Please select the gender：',
        dropdown: {
          option1: 'Male',
          option2: 'Female'
        }
      },
      fieldSpayedOrNeutered: {
        label: 'spayed/neutered：',
        placeholder: 'Please select whether your pet has been spayed/neutered：',
        dropdown: {
          option1: 'spayed',
          option2: 'neutered'
        }
      },
      fieldWeight: {
        label: 'Weight（kg）：',
        placeholder: 'Please enter your weight（kg）：',
      },
      fieldCustomerAge: {
        label: 'Age：',
        placeholder: 'Please enter your age：',
      },
      fieldCellphone: {
        label: 'Contact information：',
        placeholder: 'Please provide your contact information：',
      },
      fieldarea: {
        label: 'Area',
        placeholder: 'Please select your area：',
        cascaderTitle: 'Please select your area：'
      },
      fieldSpecificUnit: {
        label: 'Detailed Symptom Description：',
        placeholder: 'Please provide a detailed Symptom Description：'

      }, fieldSymptom: {
        label: 'Clinical Symptoms：',
        placeholder: 'Please select clinical symptoms：'

      },   fieldRemarks:{
        label: 'Remarks',
        placeholder: 'Please select clinical Remarks：'

      },
      submitButton: 'Submitted'
    }
  }
}