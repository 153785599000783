import Vue from 'vue'
import axios from 'axios'
import router from '@/router/index.js'
import { VueAxios } from './axios'
import { showToast,showFailToast } from 'vant';
 
let flag = true

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
  timeout: 50000, // 请求超时时间
  withCredentials: true //跨域处理
})




const err = (error) => {

  if (error.response && flag) {
    const data = error.response.data
    const msg = error.response.message
    const token = sessionStorage.getItem(ACCESS_TOKEN)
    if (error.response.status === 403) {
     // ElMessage.error(msg);
    }
    if(error.response.status === 401){
      router.push({
        path: "/404",
      });
 
      showFailToast('登录超时，请重新登录');
    }
    if ((error.response.status === 400) && !(data.result && data.result.isLogin)) {
      flag = false
      if (error.response.data.error_description == 'Bad credentials') {
        //ElMessage.error('');
        showFailToast('你输入的密码有误');
      } else {
        showFailToast(error.response.data.error_descriptio);
        //ElMessage.error(n);
      }

      if (token) {
       
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  // 导出
  if (response.headers['content-type'].indexOf('application/vnd.ms-excel') > -1
    && !response.data.hasOwnProperty('responseCode')) {
    return response;
  }
  if (response.data.code > 200) {
    showFailToast(response.data.msg);
    return Promise.reject(response)
  } else {
    return response.data
  }
}, err)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
