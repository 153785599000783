<template>
    <router-view v-wechat-title='$route.meta.title'/>
   
</template>

<script>
export default {};
</script>

<style>

</style>