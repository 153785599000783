import { createRouter, createWebHistory ,createWebHashHistory} from 'vue-router'
import index from '../views/home/index.vue'


const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    meta: {
      title: '信息收集'
    },
  },
  {
    path: "/custom",
    name: "custom",
    meta: {
      title: '信息收集'
    },
    component: () => import("../views/home/index.vue")
  },
  {
    path: "/pet",
    name: "pet",
    meta: {
      title: '信息收集'
    },
    component: () => import("../views/pet/index.vue")
  },

  {
    path: "/submitted",
    name: "submitted",
    meta: {
      title: '信息收集'
    },
    component: () => import("../views/abnormal/submitted.vue")
  },
  
  {
    path: "/successPage",
    name: "successPage",
    meta: {
      title: '信息收集'
    },
    component: () => import("../views/successPage/successPage.vue")
  },
  {
    path: "/404",
    name: "404",
    meta: {
      title: '信息收集'
    },
    component: () => import("../views/abnormal/404.vue")
  },
  {
    path: "/403",
    name: "403",
    meta: {
      title: '403'
    },
    component: () => import("../views/abnormal/403.vue")
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: {
      title: '信息收集'
    },
    component: () => import('../views/abnormal/404.vue'),
    //    404 page
  }

];
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
