
// ja-jp.js
export default {
    corporateName: '信息收集',
    custom: {
      title: '异常反馈',
      copywriting: '下記のフィールドを記入してください。',
      forms: {
        fieldcustomerName: {
          label: '氏名：',
          placeholder: '氏名：',
        },
        fieldgender: {
          label: '性別：',
          placeholder: '性別',
          dropdown: {
            option1: '男',
            option2: '女'
          }
        },
        fieldcustomerAge: {
          label: '年齢',
          placeholder: '年齢：',
        },
        fieldacquisitionDate: {
          label: '採血日：',
          placeholder: '採血日：',
        },
        fieldContactInformation: {
          label: '連絡先情報：',
          placeholder: '連絡先情報：',
        },
        fieldSampleSendingUnit: {
          label: '報告書送付先：',
          placeholder: '報告書送付先：',
        },
        fieldarea: {
          label: '地域：',
          placeholder: '地域：',
          cascaderTitle: '地域：'
        },
        fieldSpecificUnit: {
          label: 'ご住所：',
          placeholder: 'ご住所：'
  
        },
        fieldSymptom: {
          label: 'からだの不具合箇所・症状:',
          placeholder: 'からだの不具合箇所・症状：'
  
        },
        submitButton: '送信'
  
  
  
  
      }
  
    },
    pet: {
      title: '异常反馈',
      copywriting: '下記のフィールドを記入してください。',
      forms: {
        fieldParentName: {
          label: 'オーナー名：',
          placeholder: 'オーナー名：',
        },
        fieldPetName: {
          label: 'ペット名：',
          placeholder: 'ペット名：',
        },
        fieldDogBreed: {
          label: '犬種名・猫種名：',
          placeholder: '犬種名・猫種名：',
        },
        fieldGender: {
          label: 'ペット性別：(オス/メス)：',
          placeholder: 'ペット性別：(オス/メス)：',
          dropdown: {
            option1: 'オス',
            option2: 'メス'
          }
        },
        fieldSpayedOrNeutered: {
          label: '避妊処置：（はい/いいえ）：',
          placeholder: '避妊処置：（はい/いいえ）：',
          dropdown: {
            option1: 'はい',
            option2: 'いいえ'
          }
        },
        fieldWeight: {
          label: '体重（キロ）：',
          placeholder: '体重（キロ）：',
        },
        fieldCustomerAge: {
          label: '年齢：',
          placeholder: '年齢：',
        },
        fieldCellphone: {
          label: '連絡先情報：',
          placeholder: '連絡先情報：',
        },
        fieldarea: {
          label: '地域：',
          placeholder: '地域：',
          cascaderTitle: '地域：'
        },
        fieldSpecificUnit: {
          label: '具体的な箇所：',
          placeholder: '具体的な箇所：'
  
        }, fieldSymptom: {
          label: '臨床症状：',
          placeholder: '臨床症状：'
  
        },
        fieldRemarks:{
          label: '备注',
          placeholder: '备注：'
  
        },
        submitButton: '送信'
      }
    }
  
  };  