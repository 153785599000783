
// zh.js
export default {
  corporateName: '信息收集',
  custom: {
    title: '异常反馈',
    copywriting: '尊敬的用户：您好！为了更好的为您服务，请您按照要求填写完整的信息。祝您健康，谢谢配合。',
    forms: {
      fieldcustomerName: {
        label: '姓名：',
        placeholder: '请输入姓名：',
      },
      fieldgender: {
        label: '性别：',
        placeholder: '请选择性别：',
        dropdown: {
          option1: '男',
          option2: '女'
        }
      },
      fieldcustomerAge: {
        label: '年龄：',
        placeholder: '请输入年龄：',
      },
      fieldacquisitionDate: {
        label: '采集日期：',
        placeholder: '点击选择采集日期：',
      },
      fieldContactInformation: {
        label: '联系方式：',
        placeholder: '请输入联系方式（电话/邮箱）：',
      },
      fieldSampleSendingUnit: {
        label: '送样单位：',
        placeholder: '请输入送样单位：',
      },
      fieldarea: {
        label: '地区：',
        placeholder: '请选择所在地区：',
        cascaderTitle: '请选择所在地区：'
      },
      fieldSpecificUnit: {
        label: '具体位置：',
        placeholder: '请输入具体位置：'

      },
      fieldSymptom: {
        label: '身体不适症状描述:',
        placeholder: '请输入身体不适症状描述：'

      },
      submitButton: '提交'




    }

  },
  pet: {
    title: '异常反馈',
    copywriting: '尊敬的用户：您好！为了更好的为您服务，请您按照要求填写完整的信息。祝您健康，谢谢配合。',
    forms: {
      fieldParentName: {
        label: '家长姓名：',
        placeholder: '请输入家长姓名：',
      },
      fieldPetName: {
        label: '宠物名：',
        placeholder: '请输入宠物名：',
      },
      fieldDogBreed: {
        label: '犬种・猫种：',
        placeholder: '请输入犬种・猫种：',
      },
      fieldGender: {
        label: '宠物性别：',
        placeholder: '请选择性别：',
        dropdown: {
          option1: '公',
          option2: '母'
        }
      },
      fieldSpayedOrNeutered: {
        label: '是否绝育：',
        placeholder: '请选择宠物是否绝育：：',
        dropdown: {
          option1: '已绝育',
          option2: '未绝育'
        }
      },
      fieldWeight: {
        label: '体重（kg）：',
        placeholder: '请输入体重（kg）：',
      },
      fieldCustomerAge: {
        label: '年龄：',
        placeholder: '请输入年龄：',
      },
      fieldCellphone: {
        label: '联系方式：',
        placeholder: '请输入联系方式：',
      },
      fieldarea: {
        label: '地区：',
        placeholder: '请选择所在地区：',
        cascaderTitle: '请选择所在地区：'
      },
      fieldSpecificUnit: {
        label: '具体位置：',
        placeholder: '请输入具体位置：'

      }, fieldSymptom: {
        label: '临床症状：',
        placeholder: '请选择临床症状：'

      },
      fieldRemarks:{
        label: '备注',
        placeholder: '请输入备注：'

      },
      submitButton: '提交'
    }
  }

};  