import { createI18n } from 'vue-i18n'
import zh from './zh'
import en from './en'
import jajp from './ja-jp'
import kokr from './ko-kr'
import { Locale } from 'vant'
//vant中的文件夹  需要的语言和本地的语言保持一致
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import jaJP from 'vant/lib/locale/lang/ja-JP'
import kokR from 'vant/lib/locale/lang/ko-KR'


const messages = {
  en: {
    ...enUS,
    ...en
  },
  zh: {
    ...zhCN,
    ...zh
  },
  jajp: {
    ...jaJP,
    ...jajp
  },
  kokr: {
    ...kokR,
    ...kokr
  }
}

function vantLocales(lang) {
  if (lang === 'en') {
    Locale.use(lang, enUS)
  } else if (lang === 'zh') {
    Locale.use(lang, zhCN)
  } else if (lang === 'jajp') {
    Locale.use(lang, jaJP)
  }
  else if (lang === 'kokr') {
    Locale.use(lang, kokR)
  }

}



const lang = (navigator.language || 'en').toLocaleLowerCase()
const language = localStorage.getItem('language') || lang.split('-')[0] || 'zh';
localStorage.setItem('language', language)
const i18n = createI18n({
  //Not available in legacy mode 解决报错问题的配置项！！！
  legacy: false,
  // 全局注册 $t方法
  globalInjection: true,
  //设置初始化语言
  locale: language,
  // 设置备用语言
  fallbackLocale: 'zh',
  messages,
})
export { i18n, vantLocales }
