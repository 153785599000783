
// ja-jp.js
export default {
    corporateName: '信息收集',
    custom: {
      title: '异常反馈',
      copywriting: '검사 접수를 위한 필수 기재사항',
      forms: {
        fieldcustomerName: {
          label: '성명：',
          placeholder: '성명：',
        },
        fieldgender: {
          label: '성별: (남/여)',
          placeholder: '성별: (남/여)',
          dropdown: {
            option1: '남',
            option2: '여'
          }
        },
        fieldcustomerAge: {
          label: '나이：',
          placeholder: '나이：',
        },
        fieldacquisitionDate: {
          label: '채혈일자：',
          placeholder: '채혈일자：',
        },
        fieldContactInformation: {
          label: '연락처：',
          placeholder: '연락처：',
        },
        fieldSampleSendingUnit: {
          label: '납품 단위：',
          placeholder: '납품 단위：',
        },
        fieldarea: {
          label: '지역：',
          placeholder: '지역：',
          cascaderTitle: '지역：'
        },
        fieldSpecificUnit: {
          label: '상세 우편주소：',
          placeholder: '상세 우편주소：'
  
        },
        fieldSymptom: {
          label: '증상:',
          placeholder: '증상：'
  
        },
        submitButton: '제출'
  
  
  
  
      }
  
    },
    pet: {
      title: '异常反馈',
      copywriting: '검사 접수를 위한 필수 기재사항',
      forms: {
        fieldParentName: {
          label: '보호자명：',
          placeholder: '보호자명：',
        },
        fieldPetName: {
          label: '환자명：',
          placeholder: '환자명：',
        },
        fieldDogBreed: {
          label: '품종：',
          placeholder: '품종：',
        },
        fieldGender: {
          label: '성별 (남아/여아)：',
          placeholder: '성별 (남아/여아)：',
          dropdown: {
            option1: '남아',
            option2: '여아'
          }
        },
        fieldSpayedOrNeutered: {
          label: '중성화 여부：',
          placeholder: '중성화 여부：',
          dropdown: {
            option1: '예',
            option2: '아니오'
          }
        },
        fieldWeight: {
          label: '체중 (KG)：',
          placeholder: '체중 (KG)：',
        },
        fieldCustomerAge: {
          label: '나이：',
          placeholder: '나이：',
        },
        fieldCellphone: {
          label: '연락처：',
          placeholder: '연락처：',
        },
        fieldarea: {
          label: '지역：',
          placeholder: '지역：',
          cascaderTitle: '지역：'
        },
        fieldSpecificUnit: {
          label: '주소：',
          placeholder: '주소：'
  
        }, fieldSymptom: {
          label: '임상증상：',
          placeholder: '임상증상：'
  
        },
        fieldRemarks:{
          label: '설명：',
          placeholder: '설명：'
  
        },
        submitButton: '제출'
      }
    }
  
  };  